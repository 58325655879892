import { Redirect, RedirectProps, useLocation, useParams } from 'react-router-dom';
import { FC } from 'react';

export const RedirectWithQueries: FC<RedirectProps & { to: string }> = props => {
  const location = useLocation();
  const params = useParams<{ partnerId?: string }>();
  console.log(
    [params?.partnerId ? '/p-' + params?.partnerId : params?.partnerId, props.to].filter(Boolean).join('/').replaceAll('//', '')
  );
  return (
    <Redirect
      {...props}
      to={{
        pathname: [params?.partnerId ? '/p-' + params?.partnerId : params?.partnerId, props.to]
          .filter(Boolean)
          .join('/')
          .replaceAll('//', '/'),
        search: location.search
      }}
    />
  );
};
