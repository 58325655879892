import { useEffect, useState } from 'react';
import { useMeMemberQuery } from '../queries/meMember.generated';

export type MemberInfo = {
  id: string;
  email: string;
  name?: string | null;
  managerName?: string;
  avatar?: string | null;
  role: string;
};

export const useMeMember = (): MemberInfo | undefined => {
  const [partnerOrSupplierMember, setMember] = useState<MemberInfo | undefined>();
  const { data, refetch } = useMeMemberQuery({ fetchPolicy: 'no-cache' });

  useEffect(() => {
    function cb() {
      refetch();
    }
    document.body.addEventListener('UPDATE_ME_DATA', cb);
    return () => {
      document.body.removeEventListener('UPDATE_ME_DATA', cb);
    };
  }, []);

  useEffect(() => {
    if (data?.me) {
      const { partnerMember, supplierMember } = data.me;

      if (partnerMember) {
        const identity = partnerMember.identities.find(v => v.provider === 'email');
        identity &&
          setMember({
            id: partnerMember.id,
            email: identity.uid,
            name: partnerMember.partner.name,
            managerName: partnerMember.name,
            role: partnerMember.role,
            avatar: partnerMember.avatar
          });
      }

      if (supplierMember) {
        const identity = supplierMember.identities.find(v => v.provider === 'email');
        identity &&
          setMember({
            id: supplierMember.id,
            email: identity.uid,
            name: supplierMember.supplier.name,
            role: supplierMember.role,
            avatar: supplierMember.avatar
          });
      }
    }
  }, [data, setMember]);

  return partnerOrSupplierMember;
};
