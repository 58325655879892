export type RoundOptions = {
  factor?: number;
  math?: boolean;
};

export function round(value: number, options: RoundOptions = {}): number {
  const { factor = 2, math = false } = options;

  if (math) {
    return Math.round(value * Math.pow(10, factor)) / Math.pow(10, factor);
  } else {
    return Math.floor(Math.round(value * Math.pow(10, factor + 1)) / 10) / Math.pow(10, factor);
  }
}

export function extractFactor(value: number): number {
  for (let i = 0; i < 6; i++) {
    if (value === round(value, { factor: i })) {
      return i;
    }
  }

  return 6;
}

export const formatNumber = (x: number, fractionDigits: number = 2) => {
  return (x || 0).toLocaleString(undefined, { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits });
};
