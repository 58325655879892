import { PropsWithChildren, FC, ReactNode } from 'react';
import { PermissionsGateProps, useHasPermissions } from './Access';

export const PermissionsGate: FC<PropsWithChildren<PermissionsGateProps>> = ({
  permissions,
  operator,
  fallback = null,
  children
}) => {
  return useHasPermissions(permissions, operator) ? <>{children}</> : <>{fallback}</>;
};

export const ConditionalGate: FC<{ condition?: boolean; fallback?: ReactNode }> = ({ condition, fallback = null, children }) => {
  return condition ? <>{children}</> : <>{fallback}</>;
};
