import { useGettext } from '@cranium/i18n';

export const usePageTitles = () => {
  const { gettext } = useGettext();

  const pageTitles = [
    { value: 'analytics', label: gettext('Analytics') },
    { value: 'inventory', label: gettext('Inventory') },
    { value: 'availability', label: gettext('Availability') },
    { value: 'days', label: gettext('Days') },
    { value: 'all', label: gettext('All') },
    { value: 'new', label: gettext('New') },
    { value: 'clients', label: gettext('Clients') },
    { value: 'dashboards', label: gettext('Dashboard') },
    { value: 'operators', label: gettext('Driver') },
    { value: 'schedule', label: gettext('Schedule') },
    { value: 'fleet', label: gettext('Fleet') },
    { value: 'activity', label: gettext('Activity') },
    { value: 'locations', label: gettext('Locations') },
    { value: 'operations', label: gettext('Operations') },
    { value: 'trucks', label: gettext('Trucks') },
    { value: 'suppliers', label: gettext('Suppliers') },
    { value: 'contractors', label: gettext('Contractors') },
    { value: 'drivers', label: gettext('Drivers') },
    { value: 'orders', label: gettext('Orders') },
    { value: 'statistics', label: gettext('Statistics') },
    { value: 'finance', label: gettext('Finance') },
    { value: 'payouts', label: gettext('Payout History') },
    { value: 'profile', label: gettext('Settings') },
    { value: 'projects', label: gettext('Projects') },
    { value: 'products', label: gettext('Products') },
    { value: 'requests', label: gettext('Requests') },
    { value: 'tax', label: gettext('Tax') },
    { value: 'widgets', label: gettext('Widgets') },
    { value: 'messages', label: gettext('Messages') },
    { value: 'blockOffAvailability', label: gettext('Block-Off Availability') },
    { value: 'request', label: gettext('Request') }
  ];

  const getPageTitle = (pageName: string) => pageTitles.find(page => page.value === pageName)?.label;

  return { getPageTitle };
};
