import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

import { useHistory } from '@/shared/navigation';
import { useGettext } from '@cranium/i18n';

const Container = styled.div`
  display: grid;
  justify-items: center;
  align-content: center;
  grid-gap: 40px;
  width: 100%;
`;

const Title = styled.div`
  max-width: 600px;
  text-align: justify;
  font-size: 24px;
  font-weight: 800;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: var(--gray-10);
`;

type PermissionDeniedPageProps = {
  action: string;
};

export const PermissionDeniedPage: React.FunctionComponent<PermissionDeniedPageProps> = ({ action }) => {
  const { gettext } = useGettext();
  const history = useHistory();
  const message = gettext(
    `Sorry, but you do not have the permission to %(action).
                        If you believe this is an error or if you need assistance, please contact the administrator or
                        support team for further assistance. Thank you for your understanding.`,
    { action }
  );

  return (
    <Container>
      <Title>{message}</Title>
      <Button type="primary" onClick={() => history.push('/')}>
        {gettext(`Back to Homepage`)}
      </Button>
    </Container>
  );
};
